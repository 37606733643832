<template>
  <b-card>

    <div class="row">
      <div class="col-md-8">
        <b-button size="md" variant="secondary" @click="downloadExcel()" v-if="$can('view', 'event_itineraries')" :disabled="isLoading">
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          /><span>{{ (!isLoading) ? 'Export Excel' : 'Tunggu..' }}</span>
        </b-button>
      </div>
      <div class="col-md-4">
        <!-- search input -->
        <div class="custom-search float-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Cari</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                name="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Tunggu..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <span
          v-if="props.column.field === 'question_formatted'"
          class="text-wrap"
        >
          <p>{{ (props.row.question) }}</p>
        </span>

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'name_formatted'"
          class="text-nowrap"
        >
          {{ (props.row.user.salutation) ? props.row.user.salutation + ". " : '' }}{{props.row.user.name + " "}}{{ (props.row.user.last_name) ? props.row.user.last_name : '' }}
        </span>

        <!-- Column: Created at -->
        <span
          v-if="props.column.field === 'created_at_formatted'"
          class="text-nowrap"
        >
          {{ (props.row.created_at) ? formatDateReverse(props.row.created_at) : '-' }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Data 1 hingga
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> dari {{ props.total }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import * as XLSX from 'xlsx'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BRow,
    BCol,
    XLSX
  },
  methods: {
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY HH:mm')
    },
    downloadExcel() {
      this.isLoading = true

      let excelRows = []

      this.rows.map(row => {

          let currentObj = {}

          currentObj = {
            first_name: (row.user) ? row.user.name : '-',
            last_name: (row.user) ? row.user.last_name : '-',
            salutation: (row.user) ? row.user.salutation : '-',
            question:  (row.question) ? row.question : '-',
            created_at: (row.updated_at) ? this.formatDateReverse(row.updated_at) : this.formatDateReverse(row.created_at),
          }

        excelRows.push(currentObj)

      })

      let excelHeaders = []

      excelRows.forEach((row, index) => {
        for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

          if(index == 0) {
            const words = key.split("_");

            let colName = []

            words.forEach((word) => {
              colName.push(word[0].toUpperCase() + word.substring(1))
            })

            excelHeaders.push(colName.join(" "))
          }

          if(value == '' || value === null) {
            excelRows[index][key] = '-'
          }
        }
      })

      const worksheet = XLSX.utils.json_to_sheet(excelRows)

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")
      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, this.pageName + "_" + Date.now() + ".xlsx", { compression: true });

      this.isLoading = false
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Question',
          field: 'question_formatted',
        },
        {
          label: 'Submitted By',
          field: 'name_formatted',
        },
        {
          label: 'Submitted At',
          field: 'created_at_formatted',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoading: false,
      pageName: "Question",

    }
  },
  props: {
    data: {
      type: Array,
      required: true,
    }
  },
  created() {    
    if(this.data !== null) {
      this.rows = []

      this.data.forEach((item) => {
        this.rows.push(item)
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
